/* eslint-disable-next-line */

/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}



p.success {
  color: green;
}

p.danger {
  color: red;
}


body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #626262;
  border-radius: 10px;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #403f3f;
}

body::-webkit-scrollbar-thumb {
  background-color: #AAA;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, .2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, .2) 50%,
      rgba(0, 0, 0, .2) 75%,
      transparent 75%,
      transparent)
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav .navbar_kic_logo{
  width: 140px;
  margin: 10px;
}
nav .navbar_kiet_logo{
  width: 70px;
  margin: 0px;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 10px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  border-radius: 20px;
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  border-radius: 20px;
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #444444;
}

.navbar-text button:hover::before {
  border-radius: 20px;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: clamp(1.6rem, 2.7vw, 5.5rem);
  margin-bottom: 16px;
  display: inline-block;
  border-radius: 20px;
}

.txt-rotate {
  color: #ffffff;
}

.banner h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: #969696;
  /* min-height: 60px; */
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner .img {
  animation: updown 3s linear infinite;
}

.btn-shine {
  /* transform: translate(0%, -50%); */
  color: #fff;
  background: linear-gradient(to right, #9f9f9f 0, #fff 10%, #868686 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
}

.banner_kiet_logo{
  width: 50px;
  position: fixed;
  bottom: 40px;
  left: 80px;
  animation: none;
  z-index: 1000;
}
.banner_kic_logo{
  width: 50px;
  position: fixed;
  bottom: 50px;
  left: 10px;
  animation: none;
  z-index: 1000;
}

@-moz-keyframes shine {
  0% {
    background-position: -50px;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@-o-keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@keyframes updown {
  0% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(-30px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

/* about */
.about-container {
  /* min-height: 500px; */
  display: flex;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.about-container .about {
  font-size: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  text-align: center;
  font-weight: 200;
}

.about-container img {
  width: 400px;

}


/* Events */
.event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  width: 100%;
  flex-wrap: wrap;
  min-height: 400px;
}

.domain-img {
  width: 200px;
  height: 240px;
}

.e-card {
  /* margin: 100px auto; */
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  position: relative;
  width: 260px;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.e-card:hover {
  scale: 1.1;
}

.wave {
  position: absolute;
  width: 486px;
  height: 600px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
}

.infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 0.6em;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.infotop img {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border-radius: 20px;
  object-fit: cover;
}

.name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  text-transform: lowercase;
  color: rgb(174, 174, 174);
  margin: 0 10px;
  padding: 2px 5px;
  border: 2px solid rgb(174, 174, 174);
  border-radius: 4px;
  transition: 0.3s ease-in-out;
}

.name>span {
  padding-right: 4px;
}

.name:hover {
  color: white;
  border: 2px solid white;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.playing .wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.playing .wave:nth-child(3) {
  animation-duration: 5000ms;
}

.wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -100px;
}

.ReactModal__Content {
  width: 300px;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

/* .item{
  min-height: 360px;
} */

.skill-slider .item img {
  /* width: 50%; */
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: -53%;
  position: absolute;
  /* bottom: 100px; */
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/* core team */

.core-team {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.core-team-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 80%;
  flex-wrap: wrap;
}

.team-box {
  width: 250px;
  /* height: 400px; */
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.team-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
}

/* team member */
.teamMemberImg {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}

.hover-btn:hover {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  color: #fff
}

.hover-btn:hover svg {
  color: white;
}

.react-multiple-carousel__arrow {
  max-width: 43px;
}

.react-multiple-carousel__arrow--left {
  right: calc(5%+ 10px);
}

/* @media (max-width: 768px) {
  .core-team-container {
    gap: 20px;
  }

  .skill-slider .item {
    height: 200px;
  }

  .skill-slider .item .teamMemberImg {
    width: 250px;
    height: 250px;
  }
} */

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 80px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  border-radius: 20px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  border-radius: 20px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
/* .footer {
  padding: 50px 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer-img{
  display: flex;
  gap: 2rem ;
  flex-wrap: wrap;
} */


.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

/* .footer img {
  width: 26%;
} */
/* .footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
} */


/* TIMELINE CSS */
.timeline {
  margin-bottom: 100px;
}

.vertical-timeline-element-content {
  background-color: red;
}

.timeline-img {
  border-radius: 10px;
  margin-bottom: 20px;
}

.blog-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.blog-container h1 {
  font-size: 45px;
}

.blog-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.blog-card {
  width: 290px;
  height: 300px;
  border-radius: 20px;
  padding: 5px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  background-image: linear-gradient(144deg, #AA367C, #5B42F3 50%, #4A2FBD);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.blog-card:hover {
  scale: 1.05;
}

.card__content {
  background: rgb(5, 6, 45);
  border-radius: 17px;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__content h3 {
  font-size: 20px;
  font-weight: 700;
  color: #4A2FBD;
}

.card__content p {
  font-weight: 400;
  color: #fff;
}

.card__content a {
  text-decoration: none;
  color: #AA367C;
}

/* pre-loader */

.loader {
  --path: #a0a7b9;
  --dot: #5628ee;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}


/* footer */
.waves>use {
  animation: move-forever 2s -2s linear infinite;
}

.waves>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 6s;
}

.waves>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 3s;
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

.footer-svg {
  position: absolute;
  left: 0;
  bottom: -100px;
  width: 100%;
  height: 30vw;
  max-height: 200px;
  opacity: 0.3;
  z-index: -1;
}

.footer-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.footer-left-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;

}

.footer-img-container {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-left-container .footer-img-container img {
  object-fit: cover;
}

.footer-divider {
  height: 100px;
  width: 1.5px;
  background-color: rgba(255, 255, 255, 0.273);
}

.footer-right-container {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-right-heading {
  margin-bottom: 20px;
  font-weight: 900;
  color: #AA367C;
}

.footer-right-container ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 0;
}

.footer-right-container ul a {
  text-decoration: none;
}

.footer-right-container ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #ffffff;
  font-family: 'Centra', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.footer-right-container ul li:hover {
  color: #AA367C;
  transform: translateX(5px);
}

.footer-designation {
  font-weight: 400;
  color: #B8B8B8;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* stats */

.course-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 34px;
  color: #000000;
}

.count-up {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  min-height: 250px;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 10px;
  box-shadow: -4px 2px 10px 0 rgba(0, 0, 0, 0.2);
  width: 200px;
  margin-bottom: 20px;
}

.counter {
  font-size: 40px;
  font-weight: bold;
  border-radius: 3px;
  position: relative;
  color: #AA367C;
}

.wrapper i {
  z-index: 1;
  font-size: 64px;
  margin-bottom: 5px
}

.statsName {
  font-size: 20px;
  color: #4A2FBD;
}

/* counter */
.counter-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#hitCounter {
  padding: 4px 6px;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  transition: all 200ms ease-in;
}

#hitCounter:active .heart {
  animation: cssAnimation 3000ms ease-in;
  transform: scale(1);
}

@keyframes cssAnimation {
  from {
    transform: scale(1.3);
  }

  to {
    transform: scale(1);
  }
}


/* TEAM PAGE CSS */

.team-Container {
  margin-top: 120px;
}

.back-button-container {
  width: 100%;
  height: 100%;
}

.back-button {
  position: fixed;
  top: 100px;
  left: 20px;
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  color: white;
  flex-direction: column;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  font-size: 20px;
}

.back-button::before {
  content: '';
  position: absolute;
  inset: 0;
  left: -5px;
  top: -1px;
  margin: auto;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.back-button::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  ;
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.back-button:hover::after {
  filter: blur(30px);
}

.back-button:hover::before {
  transform: rotate(-180deg);
}

.back-button:active::before {
  scale: 0.7;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-weight: 700;
}

.notFound-container:nth-child(1) {
  width: 40vw;
}

.notFound-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notFound-btn {
  color: white;
}

/* REFERENCE PAGE CSS */

.reference-section {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.reference-section-heading {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  background-color: #121212;
}

.reference-items {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  /* margin: 30px; */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 8px 28px -9px rgba(255, 253, 253, 0.45);
  width: 90%;
  margin-top: 20px;
}

.reference-items h2 {
  text-align: center;
  color: rgb(46, 45, 45);
  font-weight: 800;
  font-size: 40px;
}

.reference-items p {
  font-weight: 200;
  margin-left: 10px;
}

.reference-items h3 {
  color: rgb(56, 56, 56);
}

.description-heading {
  font-weight: 800;
  color: rgb(46, 45, 45);
}

.bullet::marker {
  color: rgb(46, 45, 45);
}

.tab-component {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 50px;
  width: 100vw;
  background: #121212;
  padding-bottom: 12px;
}

.tab-component .nav-item button {
  color: white;
  border-radius: 10px;
}

.nav-tabs {
  border: none;
}

/* From Uiverse.io by vinodjangid07 */ 
.cookieCard {
  width: 300px;
  height: 180px;
  background: linear-gradient(to right,#5D00C1,#B6007D);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.cookieCard::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right,#5D00C1,#B6007D);
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
  opacity: 0.5;
}

.cookieHeading {
  font-size: 1.5em;
  font-weight: 600;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription {
  font-size: 0.9em;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription a {
  color: rgb(241, 241, 241);
}

.acceptButton {
  padding: 11px 20px;
  background-color: #5D00C1;
  transition-duration: .2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  z-index: 2;
}

.acceptButton:hover {
  background-color: #B6007D;
  transition-duration: .2s;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #000;
    border-radius: 20px;
  }

  .navbar-link {
    margin-top: 10px;
  }

  .banner h1 {
    min-height: 100px;
  }

  .about-container {
    flex-direction: column;
  }

  .about-container img {
    width: 100%;
  }

  .about-container .about {
    width: 100%;
    font-size: 16px;
  }

  .item {
    min-height: 260px;
  }

  .banner {
    padding: 150px 0;
  }

  .core-team-container {
    gap: 0px;
  }

  .react-multiple-carousel__arrow--left {
    left: 0%;
    bottom: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: -10%;
    bottom: 0;
  }

  .footer-container {
    flex-direction: column;
  }

  .footer-divider {
    height: 2px;
    width: 150px;
    margin: 1.5rem 0 1.5rem 0;
  }

  .footer-left-container,
  .footer-right-container {
    width: 100vw;
  }

  .footer-img-container {
    height: 100px;
    width: 100px;
  }

  .notFound-container:nth-child(1) {
    width: 90vw;
  }

  nav .navbar_kic_logo{
    width: 100px;
  }
  nav .navbar_kiet_logo{
    width: 50px;
  }
}

.toastify {
  z-index: 1000;
  position: fixed;
  top: 100px;
  right: 10px;
  background-color: white;
  padding: 10px 5px;
  border-radius: 10px;
  width: 250px;
  text-align: center;
  font-weight: 900;
}

.toastify p {
  margin-top: 14px;
}

.text-bg {
  background-color: #121212;
  font-size: 18px;
  cursor: default;
  margin-top: 10px;
  color: #403f3f;
}

.hoverText {
  transition:
    0.35s font-weight,
    0.35s color;
}

.hoverText:hover {
  font-weight: 900;
  color: #4A2FBD;
}

/* To the right */
.hoverText:hover+.hoverText {
  font-weight: 500;
  color: #AA367C;
}

.hoverText:hover+.hoverText+.hoverText {
  font-weight: 300;
}

/* To the left */
.hoverText:has(+ .hoverText:hover) {
  font-weight: 500;
  color: r#AA367C;
}

.hoverText:has(+ .hoverText + .hoverText:hover) {
  font-weight: 300;
}